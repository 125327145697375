import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { assetURL, environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { BehaviorSubject, catchError, debounceTime, filter, of, switchMap, map, Observable } from 'rxjs';
import { AccountVerificationUtils } from 'src/app/services/account-verification.utils';
import { AccountVerificationThreatMetrixService } from 'src/app/services/account-verification-tmx.service';
import { AccountVerificationTransmitService } from 'src/app/services/transmit.service';

@Component({
  selector: 'app-account-validation',
  templateUrl: './account-validation.component.html',
  styleUrls: ['./account-validation.component.scss'],
  providers: [MaskAccountPipe]
})
export class AccountValidationComponent implements OnInit, AfterViewInit {

  public validationForm: UntypedFormGroup;
  public submitted = false;
  public loading = false;
  public countries = ['United States', 'Brazil', 'Germany (Deutsche Bank Only)', 'Indonesia', 'India', 'Mexico',
    'Nepal', 'Nigeria', 'Pakistan', 'South Korea', 'United Kingdom (Deutsche Bank Only)', 'Vietnam'];
  public countryCodes = {
    'Brazil': 'BR',
    'Germany (Deutsche Bank Only)': 'DE',
    'United Kingdom (Deutsche Bank Only)': 'UK',
    'Indonesia': 'ID',
    'India': 'IN',
    'Mexico': 'MX',
    'Nepal': 'NP',
    'Nigeria': 'NG',
    'Pakistan': 'PK',
    'South Korea': 'KR',
    'Vietnam': 'VN',
    'United States': 'US'
  }
  public bankIdTypes: string[] = [];
  public bankIdOptions = {
    'BR': ['IBAN', 'SWIFT ID', 'Brazil Bank Code'],
    'DE': ['IBAN', 'SWIFT ID'],
    'UK': ['IBAN', 'SWIFT ID'],
    'ID': ['SWIFT ID'],
    'IN': ['IFSC', 'SWIFT ID'],
    'MX': ['CLABE', 'SWIFT ID'],
    'NP': ['SWIFT ID'],
    'NG': ['SWIFT ID'],
    'PK': ['SWIFT ID'],
    'KR': ['SWIFT ID'],
    'VN': ['SWIFT ID'],
    'US': ['USABA', 'SWIFT ID']
  };
  public bankAccountOptions = {
    'BR': ['Account Number', 'IBAN'],
    'DE': ['Account Number', 'IBAN'],
    'UK': ['Account Number', 'IBAN'],
    'ID': ['Account Number'],
    'IN': ['Account Number'],
    'MX': ['CLABE'],
    'NP': ['Account Number'],
    'NG': ['Account Number'],
    'PK': ['Account Number', 'BBAN', 'IBAN'],
    'KR': ['Account Number'],
    'VN': ['Account Number'],
    'US': ['Account Number']
  };

  statusMessages: { [key: string]: string } = {
    INITIATED: 'This account verification is in process.  Please see below for the details of recent inquiry.',
    COMPLETED: 'This account has been validated previously. Please see below for the result of most recent inquiry.',
    TERMINATED: `Account Verification Service is Unavailable – Your request cannot be processed at this time.  \
                If the problem persists, please click <a href="${environment.acc_verif_universalLoginCase}" target="\_blank\">here</a>\
                to open a Service Case, or contact Client Services \
                at <strong>877-550-5933 (401-282-1362)</strong> or email us at \
                <a href="mailto:clientservices@mail.client.citizensbank.com">clientservices@mail.client.citizensbank.com</a>, \
                24 hours a day, 7 days a week.`,
    BADREQUEST: `There was an error processing your request. \
                Please contact Client Services to open a case and allow us to investigate further. \
                Client Services can be reached at <strong>877-550-5933 (401-282-1362)</strong> or via email at \
                <a href="mailto:clientservices@mail.client.citizensbank.com">clientservices@mail.client.citizensbank.com</a>, 24 hours a day, 7 days a week.`
  };

  public bankAccountTypes: string[] = [];
  public isBrazil: boolean = false;
  public credentials;
  public companyIds: Map<string, string[]> = new Map();
  public billingAccountList: Map<string, [string, string]> = new Map();
  public selectedBillingAccount;
  public maskedAccountNum;
  public termsAndConditions: string = '';
  public accountNum;
  public isChecked = false;
  private modalRef: NgbModalRef;
  public validatedInfo: any;
  accValidationForm: boolean = true;
  validationMsg: string;
  accAlreadyVerified: boolean;
  private validateAccountRequestBody: any;
  private validateAccountRequestHeaders: { universalId: string | null; companyId: string | undefined; userId: string | undefined; getStatusIfExists: string; };
  private reValidateAccountRequestHeaders: any;
  validationPendingMsg: boolean;
  @ViewChild('termsContent') termsContent: ElementRef;
  mergedAck: any;
  errorModal: boolean;
  lastBankId = '';
  bankName = '';
  errorMessage: string | null = null;
  tmxProfiling: boolean = false;
  profiledUrl = environment.acc_verif_tmxProfiledUrlPrefix + '/sso/dashboard';
  private usabaCalled: boolean = false;
  private tmxUnivSessionId: string;
  unMaskedAccountNum: string;
  disableForm: boolean = false;
  shouldStatusToFalse: boolean = false;
  hideCaret: boolean = false;
  aoCompanyIds: string[] = [];

  private isEligibleForAcctNameInquirySubject = new BehaviorSubject<boolean>(false);
  isEligibleForAcctNameInquiry$ = this.isEligibleForAcctNameInquirySubject.asObservable();

  constructor(private formBuilder: FormBuilder,
    private modal: NgbModal,
    private service: ApiService,
    private alertService: AlertService,
    private maskAccountPipe: MaskAccountPipe,
    private spinnerService: SpinnerService,
    private utils: AccountVerificationUtils,
    private threatMetrixService: AccountVerificationThreatMetrixService,
    private transmitService: AccountVerificationTransmitService) { }

  get f() {
    return this.validationForm.controls;
  }

  get assetURL() {
    return assetURL;
  }

  ngOnInit(): void {
    const ack = sessionStorage.getItem('ack');
    if (ack) {
      this.mergedAck = JSON.parse(ack)
    }
    this.createForm();
    this.onCountryChange();
    this.service.getTermsAndConditionsFromCDN().subscribe((res) => {
      console.log('cdn res', res);
      this.termsAndConditions = res;
    })
    const credStore = sessionStorage.getItem('credData');
    if (credStore) {
      this.credentials = JSON.parse(credStore).results ?? [];
    }
    this.credentials.filter((item) => item.applicationName.toLowerCase() === 'accessoptima').forEach((item) => {
      const userId = item.userId.toUpperCase();
      const compId = item.compId.toUpperCase()
      if (!this.companyIds.has(userId)) {
        this.companyIds.set(userId, []);
      }
      this.companyIds.get(userId)!.push(compId)
      this.aoCompanyIds.push(compId);
      console.log(this.companyIds);
    });

    const compId = Array.from(this.companyIds.values()).flat();
    this.service.billingAccountList(compId).subscribe({
      next: (res) => {
        res.forEach((item) => {
          this.accountNum = item.billingAccountNum
          this.billingAccountList.set(this.accountNum, [item.billingBankCd, item.businessCifId]);
          if (this.billingAccountList.size === 1) {
            this.selectedBillingAccount = this.billingAccountList.keys().next().value;
            this.unMaskedAccountNum = this.selectedBillingAccount;
            this.maskedAccountNum = this.maskAccountPipe.transform(this.selectedBillingAccount);
          }
        })
      },
      error: (err) => {
        console.error(err);
      }
    })

    this.getBankNameForUSABA();
    this.tmxUnivSessionId = this.loadTmxUnivLoginServ();
    this.service.getProfilingFlagFromCDN().subscribe(res => {
      this.tmxProfiling = res.profilingData;
    })

  }

  ngAfterViewInit(): void {
    this.updateBankAccountNumberValidators(this.validationForm.get('bankAccountType')?.value);
    this.validationForm.get('bankAccountType')?.valueChanges.subscribe((value) => {
      this.updateBankAccountNumberValidators(value);
    });

    this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
      this.updateBankIdValidators(value);
    });

    if (this.bankIdTypes.length === 1) {
      this.updateBankIdValidators(this.validationForm.get('bankIdType')?.value);
    }
    if (this.tmxProfiling) {
      this.transmitService.initializeTransmit(environment.acc_verif_transmitUrl).subscribe((res) => {
        console.log('transmit initialize', res);
      });
    }
  }

  createForm(): void {
    this.validationForm = this.formBuilder.group({
      country: ['US'],
      bankIdType: [this.bankIdOptions['US']],
      bankId: ['', Validators.required],
      bankAccountType: ['', Validators.required],
      bankAccountNumber: ['', Validators.required],
      beneficiaryType: ['individual'],
      businessName: [''],
      beneficiaryFirstName: [''],
      beneficiaryMiddleName: [''],
      beneficiaryLastName: [''],
      billingAccount: ['', Validators.required],
      terms: [false, Validators.requiredTrue]
    }, { validators: this.minLengthCombinedValidator(3) });
  }

  loadTmxUnivLoginServ(): string {
    const tmxUnivSessId = this.threatMetrixService.initThreatMetrixService('Universal');
    this.threatMetrixService.addEnhancedProfilingScriptTag(tmxUnivSessId);
    return tmxUnivSessId;
  }

  get showBenificiary(): Observable<boolean> {
    return this.isEligibleForAcctNameInquiry$.pipe(
      map((checkEligibility: any) => this.validationForm.get('country')?.value !== 'US' || 
      (this.validationForm.get('country')?.value === 'US' && checkEligibility && this.validationForm.get('bankId')?.value.length >= 9)))
  }

  getBankNameForUSABA() {
    const universalId = sessionStorage.getItem('universalId');
    this.validationForm.get('bankId')?.valueChanges.pipe(
      debounceTime(300),
      filter(value => {
        const bankIdType = this.validationForm.get('bankIdType')?.value;
        return bankIdType === 'USABA' && value.length === 9 && !value.includes(' ') && /^[0-9]{9}$/.test(value)
      }),
      switchMap(value => {
        this.lastBankId = value;
        return this.service.postRoutingNumber(value, universalId)
          .pipe(
            catchError((error) => {
              console.error('error in api', error);
              this.errorMessage = this.service.errorMessage;
              this.disableForm = true;
              this.isEligibleForAcctNameInquirySubject.next(false);
              return of(null);
            })
          );
      })
    ).subscribe({
      next: (bankId) => {
        if (bankId) {
          this.errorMessage = null;
          const currentBankId = this.validationForm.get('bankId')?.value;
          this.bankName = bankId?.data?.bankName;
          this.isEligibleForAcctNameInquirySubject.next(bankId?.data?.isEligibleForAcctNameInquiry);
          this.validationForm.get('bankId')?.setValue(`${currentBankId} ${this.bankName}`, { emitEvent: false });
          this.validationForm.get('bankId')?.clearValidators();
          this.validationForm.get('bankId')?.updateValueAndValidity();
        } else {
          this.isEligibleForAcctNameInquirySubject.next(false);
        }
      }, error: (err) => {
        console.error('err in api', err);
      }
    });

    this.validationForm.get('bankId')?.valueChanges.pipe(
      filter(value => value.split(' ')[0].length !== 9 && value.includes(' '))
    ).subscribe({
      next: () => {
        const currentBankId = this.validationForm.get('bankId')?.value.split(' ')[0];
        this.validationForm.get('bankId')?.setValue(currentBankId + '', { emitEvent: false });
        this.validationForm.get('bankId')?.setValidators([Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
        this.validationForm.get('bankId')?.updateValueAndValidity();
      }
    });


    this.validationForm.get('bankId')?.valueChanges.pipe(
      filter(value => !value.includes(this.bankName) && value.length !== 9)
    ).subscribe({
      next: () => {
        const currentBankId = this.validationForm.get('bankId')?.value;
        this.validationForm.get('bankId')?.setValue(currentBankId + '', { emitEvent: false });
        this.validationForm.get('bankId')?.setValidators([Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
        this.validationForm.get('bankId')?.updateValueAndValidity();
        this.isEligibleForAcctNameInquirySubject.next(false);
      }
    });
  }

  updateBankIdValidators(type) {
    const bankIdControl = this.validationForm.get('bankId');

    switch (type) {
      case 'USABA':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
        break;
      case 'IBAN':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9A-Za-z-]{5,34}$/)]);
        break;
      case 'CLABE':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{18}$/)]);
        break;
      case 'IFSC':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9]{11}$/)]);
        break;
      case 'SWIFT ID':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^([a-zA-Z0-9]{8}|[a-zA-Z0-9]{11})$/)]);
        break;
      case 'Brazil Bank Code':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
        break;
      default:
        bankIdControl?.setValidators(Validators.required);
    }
    bankIdControl?.updateValueAndValidity();
  }

  updateBankAccountNumberValidators(type) {
    const bankAccountNumberControl = this.validationForm.get('bankAccountNumber');

    switch (type) {
      case 'Account Number':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^(?![ .-])[a-zA-Z0-9](?:[a-zA-Z0-9 .-]{0,34}[a-zA-Z0-9])?$/)]);
        break;
      case 'IBAN':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^[0-9A-Za-z-]{5,34}$/)]);
        break;
      case 'CLABE':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{18}$/)]);
        break;
      case 'BBAN':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9]{10,30}$/)]);
        break;
      default:
        bankAccountNumberControl?.setValidators(Validators.required);
    }
    bankAccountNumberControl?.updateValueAndValidity();
  }

  minLengthCombinedValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstName = control.get('beneficiaryFirstName')?.value || '';
      const middleName = control.get('beneficiaryMiddleName')?.value || '';
      const lastName = control.get('beneficiaryLastName')?.value || '';
      const totalLength = firstName.length + middleName.length + lastName.length;
      if (!firstName && !middleName && !lastName) {
        return null;
      }
      return totalLength >= minLength ? null : { minLengthCombined: true };
    };
  }

  onCountryChange(): void {
    const selectedCountry = this.validationForm.get('country')?.value;
    this.bankIdTypes = this.bankIdOptions[selectedCountry] || [];
    this.bankAccountTypes = this.bankAccountOptions[selectedCountry] || [];
    this.resetDefaultValues(false);

    if (selectedCountry === 'US') {
      this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
        if (value) {
          this.validationForm.get('bankAccountType')?.disable();
        }
      })
    }

    if (this.bankIdTypes.length === 1) {
      this.validationForm.get('bankIdType')?.setValue(this.bankIdTypes[0]);
      this.validationForm.get('bankIdType')?.disable();
      this.updateBankIdValidators(this.bankIdTypes[0]);
    } else {
      this.validationForm.get('bankIdType')?.enable();
      this.validationForm.get('bankIdType')?.setValue('');
    }

    if (selectedCountry === 'US') {
      this.validationForm.get('bankIdType')?.setValue(this.bankIdTypes[0]);
      this.validationForm.get('bankIdType')?.enable();
      this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
        if (value) {
          this.validationForm.get('bankAccountType')?.disable();
        }
      })
    }


    if (this.bankAccountTypes.length === 1) {
      this.validationForm.get('bankAccountType')?.setValue(this.bankAccountTypes[0]);
      this.validationForm.get('bankAccountType')?.disable();
      this.updateBankAccountNumberValidators(this.bankAccountTypes[0]);
    } else {
      this.validationForm.get('bankAccountType')?.enable();
      this.validationForm.get('bankAccountType')?.setValue('');
    }

    if (selectedCountry === 'IN' || selectedCountry === 'US') {
      this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
        if (value) {
          this.validationForm.get('bankAccountType')?.setValue('Account Number');
          this.validationForm.get('bankAccountType')?.disable();
        }
      })
    }

    if (selectedCountry === 'MX') {
      this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
        if (value) {
          this.validationForm.get('bankAccountType')?.setValue('CLABE');
          this.validationForm.get('bankAccountType')?.disable();
        }
      })
    }

    if (selectedCountry == 'DE' || selectedCountry == 'UK' || selectedCountry == 'BR') {
      this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
        if (value === 'IBAN') {
          this.hideCaret = true;
          this.validationForm.get('bankAccountType')?.setValue('IBAN');
          this.validationForm.get('bankAccountType')?.disable();
        } else {
          this.hideCaret = false;
          this.validationForm.get('bankAccountType')?.enable();
        }
        this.updateBankIdValidators(value);
      });
    }

    this.isBrazil = selectedCountry === 'BR';

    if (this.isBrazil) {
      this.validationForm.addControl('nationalId', this.formBuilder.control('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9/.,-]{1,35}$/)]));
      this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
        this.hideCaret = true;
        if (value === 'Brazil Bank Code') {
          this.validationForm.addControl('branchId', this.formBuilder.control('', [Validators.required, Validators.pattern(/^[0-9-]{1,5}$/)]));
          this.validationForm.get('bankAccountType')?.setValue('Account Number');
          this.validationForm.get('bankAccountType')?.disable();
        } else if (value === 'SWIFT ID') {
          this.validationForm.get('bankAccountType')?.setValue('IBAN');
          this.validationForm.get('bankAccountType')?.disable();
        }
        else {
          this.validationForm.get('bankAccountType')?.disable();
          this.validationForm.removeControl('branchId');
        }
      })
    } else {
      this.validationForm.removeControl('branchId');
      this.validationForm.removeControl('nationalId');
    }
  }

  resetDefaultValues(resetCountry: boolean) {
    if (resetCountry) {
      this.validationForm.get('country')?.setValue('');
      this.validationForm.get('bankAccountType')?.setValue('');
      this.validationForm.get('bankAccountType')?.enable();
      this.validationForm.get('bankIdType')?.setValue('');
      this.validationForm.get('bankIdType')?.enable();
    }

    this.validationForm.get('bankIdType')?.setValue('');
    this.validationForm.get('bankId')?.setValue('');
    this.errorMessage = '';
    this.validationForm.get('bankAccountNumber')?.setValue('');
    this.validationForm.get('terms')?.setValue('');
    this.validationForm.get('beneficiaryFirstName')?.setValue('');
    this.validationForm.get('beneficiaryMiddleName')?.setValue('');
    this.validationForm.get('beneficiaryLastName')?.setValue('');
    this.validationForm.get('businessName')?.setValue('');
    if (this.billingAccountList.size > 1) {
      this.validationForm.get('billingAccount')?.setValue('');
    }
    if (this.validationForm.get('nationalId')) {
      this.validationForm.get('nationalId')?.setValue('');
    }
    if (this.validationForm.get('branchId')) {
      this.validationForm.get('branchId')?.setValue('');
    }
  }

  onBack(): void {
    this.modal.dismissAll();
  }

  openTerms(content: any) {
    this.modalRef = this.modal.open(content, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });

  }

  onSubmit(): void {
    this.spinnerService.setText('Please wait...')
    this.spinnerService.showSpinner();
    if (!this.validationForm.valid) {
      console.log('form is invalid');
    } else {
      if (this.tmxProfiling) {
        const universalId = sessionStorage.getItem('universalId');
        const profilingData = this.postAccountValidationDetails().body;
        this.transmitService.authenticate(universalId, profilingData, 'validate_account', this.tmxUnivSessionId, this.profiledUrl)
          .then((res) => {
            console.log('transmit res', res);
            console.log('form', this.validationForm.value);
            this.postAccountValidationDetails();
            const headers = this.postAccountValidationDetails().headers;
            const body = this.postAccountValidationDetails().body
            const revalidateHeaders = this.postAccountValidationDetails().revalidateHeaders;
            console.log('body', body);
            console.log('headers', headers);
            console.log('submit clicked', this.validationForm.value);
            this.validateAccountRequestBody = body;
            this.validateAccountRequestHeaders = headers;
            this.reValidateAccountRequestHeaders = revalidateHeaders;
            this.accountValidation(headers, body)
          }).catch((error) => {
            this.spinnerService.hideSpinner();
            console.error('transmit error', error);
            if (this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'status', 'error', 'numcode') === '3203') {
              this.alertService.error('There was an error processing your request, please contact client services at 1-877-550-5933 for more information');
            }
          })
      } else {
        console.log('form', this.validationForm.value);
        this.postAccountValidationDetails();
        const headers = this.postAccountValidationDetails().headers;
        const body = this.postAccountValidationDetails().body;
        const revalidateHeaders = this.postAccountValidationDetails().revalidateHeaders;
        console.log('body', body);
        console.log('headers', headers);
        console.log('submit clicked', this.validationForm.value);
        this.validateAccountRequestBody = body;
        this.validateAccountRequestHeaders = headers;
        this.reValidateAccountRequestHeaders = revalidateHeaders;
        this.accountValidation(headers, body)
      }

    }
  }

  newValidationRequest() {
    this.resetDefaultValues(true);
    this.validationPendingMsg = false;
    this.accValidationForm = true;
  }

  onBackFromPendingModal() {
    this.validationPendingMsg = false;
    this.modal.dismissAll();
  }

  revalidateRequest() {
    this.spinnerService.setText('Please wait...')
    this.spinnerService.showSpinner();
    this.shouldStatusToFalse = true;
    this.accountValidation(this.reValidateAccountRequestHeaders, this.validateAccountRequestBody)
  }

  accountValidation(headers, body) {
    this.validationPendingMsg = false;
    this.errorModal = false;
    console.log(body);
    this.service.postAccountValidation(headers, body, this.aoCompanyIds).subscribe({
      next: (res) => {
        console.log('res from validation', res);
        // check if account is pre-validated
        let status = res.data.accountVerificationStatus;
        if (status) {
          this.validationMsg = this.statusMessages[status?.inquiryStatus] || 'Unknown status. Please check again or contact support.';
          switch (status?.inquiryStatus) {
            case 'INITIATED':
              this.accAlreadyVerified = true;
              this.accValidationForm = false;
              this.errorModal = false;
              break;
            case 'COMPLETED':
              this.accAlreadyVerified = true;
              this.accValidationForm = false;
              this.errorModal = false;
              break;
            case 'TERMINATED':
              if (status?.closureMessage.startsWith('400')) {
                this.errorModal = true;
                this.accAlreadyVerified = false;
                this.accValidationForm = true;
                this.validationMsg = this.statusMessages['BADREQUEST'];
              } else {
                this.accAlreadyVerified = false;
                this.accValidationForm = true;
                this.errorModal = true;
              }
              break;
          }
          this.validatedInfo = res?.data?.accountVerificationStatus;
          this.spinnerService.hideSpinner();
        } else {
          let refId = res?.data?.inquiryIdentifier?.referenceId;
          const universalId = sessionStorage.getItem('universalId');
          // check account validation after 10 secs wait time...
          setTimeout(() => {
            this.service.inquireByRefId(refId, universalId).subscribe({
              next: res => {
                this.spinnerService.hideSpinner();
                switch (res?.data?.inquiryStatus) {
                  case 'INITIATED':
                    this.validationPendingMsg = true;
                    break;
                  case 'COMPLETED':
                    this.validationMsg = 'Account Validation completed successfully.'
                    this.accValidationForm = false;
                    this.validatedInfo = res.data;
                    break;
                  case 'TERMINATED':
                    const closureMessage = JSON.parse(res?.data.closureMessage);
                    const systemStatusMessage = closureMessage?.systemStatusMessage;
                    const errorCode = systemStatusMessage.split(/[-]/)[0] || '';
                    this.errorModal = true;
                    this.accAlreadyVerified = false;
                    this.accValidationForm = true;
                    if (closureMessage?.systemStatusCode.startsWith('50')) {
                      this.validationMsg = this.statusMessages['TERMINATED'] + `(Error Code: ${errorCode})`;
                    } else {
                      this.validationMsg = this.statusMessages['BADREQUEST'] + `(Error Code: ${errorCode})`;
                    }
                    break;
                }
                this.service.setEnableHistory(true);
              },
              error: error => {
                this.spinnerService.hideSpinner();
                if (error.name === 'TimeoutError') {
                  this.validationPendingMsg = true;
                }
              }
            })
          }, 10000)
        }
      },
      error: (err) => {
        this.spinnerService.hideSpinner();
        console.log(err);
        this.errorModal = true;
        this.accAlreadyVerified = false;
        this.accValidationForm = true;
        this.validationMsg = this.statusMessages['TERMINATED'];
      }
    })

  }

  getNested(obj: any, ...args: any) {
    return args.reduce((obj: any, level: any) => obj && obj[level], obj);
  }

  postAccountValidationDetails() {
    let inquiryIdentifier: any;
    if (this.billingAccountList.size === 1) {
      inquiryIdentifier = {
        "billingAccount": this.unMaskedAccountNum,
        "billingBank": this.billingAccountList.get(this.unMaskedAccountNum)?.[0]
      }
    } else {
      inquiryIdentifier = {
        "billingAccount": this.validationForm.value.billingAccount,
        "billingBank": this.billingAccountList.get(this.validationForm.value.billingAccount)?.[0]
      }
    }

    let accountType: any;
    if (this.validationForm.value.bankIdType === 'IBAN' || (this.validationForm.value.country === 'BR' && this.validationForm.value.bankIdType === 'SWIFT ID')) {
      accountType = 'IBAN';
    } else if (this.validationForm.value.country === 'BR' && this.validationForm.value.bankIdType === 'Brazil Bank Code') {
      accountType = this.utils.replaceSpacesWithUnderScore('Account Number');
    } else if (this.validationForm.value.country === 'NP' || this.validationForm.value.country === 'US' || this.validationForm.value.country === 'VN' ||
      this.validationForm.value.country === 'KR' || this.validationForm.value.country === 'NG' || this.validationForm.value.country === 'IN' ||
      this.validationForm.value.country === 'ID'
    ) {
      accountType = this.utils.replaceSpacesWithUnderScore('Account Number');
    } else if (this.validationForm.value.country === 'MX' && (this.validationForm.value.bankIdType === 'CLABE' || this.validationForm.value.bankIdType === 'SWIFT ID')) {
      accountType = 'CLABE';
    } else {
      accountType = this.utils.replaceSpacesWithUnderScore(this.validationForm.value.bankAccountType);
    }

    let idType: any;
    if (this.bankIdTypes.length === 1) {
      idType = this.utils.replaceSpacesWithUnderScore(this.bankIdTypes[0]);
    } else {
      idType = this.utils.replaceSpacesWithUnderScore(this.validationForm.value.bankIdType);
    }
    const body = {
      "accountIdentifier": {
        "country": this.validationForm.value.country,
        "lastName": this.validationForm.value.beneficiaryType === 'individual' ? this.validationForm.value.beneficiaryLastName : undefined,
        "middleName": this.validationForm.value.beneficiaryType === 'individual' ? this.validationForm.value.beneficiaryMiddleName : undefined,
        "firstName": this.validationForm.value.beneficiaryType === 'individual' ? this.validationForm.value.beneficiaryFirstName : undefined,
        "accountType": accountType,
        "businessName": this.validationForm.value.beneficiaryType === 'company' ? this.validationForm.value.businessName : undefined,
        "accountNumber": this.utils.removeHyphen(this.validationForm.value.bankAccountNumber),
        "nationalId": this.validationForm.value.nationalId ? this.validationForm.value.nationalId : undefined
      },
      "bankIdentifier": {
        "idType": idType,
        "idValue": this.validationForm.value.bankId.split(' ')[0],
        "branchId": this.validationForm.value.branchId ? this.validationForm.value.branchId : undefined
      },
      "inquiryIdentifier": inquiryIdentifier
    };

    let compId: any;
    if (this.billingAccountList.size === 1) {
      compId = this.billingAccountList.get(this.unMaskedAccountNum)?.[1]
    } else {
      compId = this.billingAccountList.get(this.validationForm.value.billingAccount)?.[1]
    }

    const headers = {
      universalId: sessionStorage.getItem('universalId'),
      companyId: compId,
      userId: this.getKeyByValue(compId, this.companyIds),
      getStatusIfExists: 'true'
    };

    const revalidateHeaders = {
      universalId: sessionStorage.getItem('universalId'),
      companyId: compId,
      userId: this.getKeyByValue(compId, this.companyIds),
      getStatusIfExists: 'false'
    }

    return { body, headers, revalidateHeaders };
  }

  onTermsClick(event: any) {
    this.isChecked = event.target.checked;
    const universalId = sessionStorage.getItem('universalId');
    const formattedDate = moment().tz('America/New_York').utc().format('YYYY-MM-DD HH:mm:ss');
    if (this.isChecked) {
      this.service.saveTermsValue(formattedDate, universalId, this.mergedAck).subscribe({
        next: (res) => {
          console.log(res);
        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }

  getKeyByValue(searchValue, map: Map<string, string[]>) {
    for (let [key, value] of map) {
      if (value.includes(searchValue)) {
        return key;
      }
    }
  }

  onContinue() {
    this.closeModal();
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

}
